  .skills-section {
    background-color: white;  /* Background color for the section */
    padding: 40px 20px;
  }
  
  .skills-title {
    text-align: center;
    color: #333;  /* Changed to a more readable color */
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .skill-item {
    background-color: blanchedalmond;
    border: 1px solid blanchedalmond;
    border-radius: 8px;
    padding: 30px;  /* Increased padding for more space inside the box */
    width: 250px;   /* Increased width for a larger box */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 10px;   /* Added margin for spacing between boxes */
  }
  
  .skill-item:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .skill-item img {
    width: 80px;  /* Increased size of the image */
    height: 80px;
    margin-bottom: 10px;
  }
  
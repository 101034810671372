/* Global styles for the About section */
.about-section {
  padding: 20px;
  background-color: #f7f7f7; /* Background color for the section */
}

/* Container styles */
.container {
  display: flex;
  flex-wrap: wrap; /* Make the layout responsive */
  align-items: stretch; /* Align content to stretch equally */
  gap: 20px; /* Space between content */
}

/* Image container styles */
.image-container {
  flex: 1 1 300px; /* Allow the image container to grow and shrink */
  max-width: 400px; /* Maximum width for larger screens */
  margin-bottom: 0; /* Remove bottom margin to align with boxes */
}

/* Profile image styles */
.profile-image {
  width: 100%; /* Make the image fill the container */
  height: 100%; /* Ensure the image height matches the container */
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Content container styles */
.content-container {
  display: flex;
  flex: 2 1 0; /* Allow content container to grow more */
  flex-wrap: wrap; /* Wrap items if needed */
  gap: 20px; /* Space between elements */
}

/* Icon card styles */
.icon-card {
  flex: 1 1 calc(33.333% - 20px); /* Responsive width with gap adjustment */
  padding: 30px; /* Increased padding inside the card */
  border-radius: 8px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Center align the text */
  transition: transform 0.2s; /* Smooth hover effect */
  background-color: #fff; /* Background color for card */
}

/* Hover effect for icon cards */
.icon-card:hover {
  transform: scale(1.05); /* Slight scaling on hover */
}

/* Icon card heading styles */
.icon-card h3 {
  margin-bottom: 10px; /* Space below the heading */
  font-size: 1.5rem; /* Font size for the heading */
}

/* Icon card paragraph styles */
.icon-card p {
  color: #555; /* Text color */
  font-size: 1rem; /* Font size for paragraphs */
}
